import React from 'react';
import styled from 'styled-components';
import { VSpace } from '@/components/spacing';
import { VisuallyLogo } from '@/features/strapi/components/logo';

export const SignupTitle = styled.span`
  width: 100%;

  font-size: 3rem;
  text-align: center;
  font-family: Inter, serif;
  font-weight: 800;
  margin: 0;
  padding: 0;
  color: black;
`;

export const SignupSmallTitle = styled(SignupTitle)`
  && {
    font-weight: 900;
    color: #475562;
    font-size: 2.1rem;
    letter-spacing: -0.67px;
  }
`;

export const SignupSubtitle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  font-size: 1.6rem;
  text-align: center;
  font-family: Inter, serif;
  font-weight: 400;
  margin: 0;
  padding: 0;

  letter-spacing: 0.2px;

  color: #80848e;
`;

export const SignupErrorMessage = styled(SignupSubtitle)`
  && {
    color: #ff0000;
    font-size: 2rem;
  }
`;

export const SignupSpinner = ({
  spaceAbove,
  spaceAfter,
}: {
  spaceAbove?: number;
  spaceAfter?: number;
}) => (
  <SignupSpinnerWrapper>
    {spaceAbove && <VSpace value={spaceAbove} />}
    <Spinner size={4} thickness={0.5} color="rgba(205,210,215)" />
    {spaceAfter && <VSpace value={spaceAfter} />}
  </SignupSpinnerWrapper>
);

const SignupSpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

interface SP {
  thickness?: number;
  size?: number;
  color?: string;
}

const Spinner = styled.span`
  border: ${(p: SP) => p?.thickness || 0.2}rem solid
    ${(p: SP) => p?.color || `currentcolor`};
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: 1s loader linear infinite;
  position: relative;
  height: ${(p: SP) => p.size || 1}rem;
  width: ${(p: SP) => p.size || 1}rem;

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SignupLogo = () => (
  <LogoWrapper>
    <VisuallyLogo wide />
  </LogoWrapper>
);

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
