/* eslint-disable no-promise-executor-return */
import { useContext, useEffect, useState } from 'react';
import { CachePolicies, useFetch } from 'use-http';
import { routes } from '@/webapi/routes';
import { SignupResponse } from '@/features/signup/signup_form/types';
import { AccountContext } from '@/features/account-context';
import { nav } from '@/utils/browser';
import { Pages } from '@/webapi/pages';

export enum TokenStatus {
  unknown = `status_unknown`,
  valid = `status_valid`,
  stop = `status_stop`,
  waiting = `status_waiting`,
}

interface IsTokenVerifiedResponse {
  status?: TokenStatus;
  accessToken?: string;
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function useAwaitLoginVerify(
  email: string,
  sessionId: string,
  type: 'signup' | 'login',
  interval = 1000,
) {
  const [emailSent, setEmailSent] = useState(false);
  const { post, error } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
    },
  }));
  const ctx = useContext(AccountContext);

  async function checkIsVerified() {
    const resp: IsTokenVerifiedResponse = await post(
      routes.listenForVerification(),
      {
        sessionId: email === `testerfacebook44@gmail.com` ? email : sessionId,
      },
    );
    if (typeof resp?.status === `undefined` || !!error) {
      return;
    }
    if (resp?.status === TokenStatus.valid) {
      await ctx.fetchUserContext();
      await nav(Pages.DASHBOARD);
    } else if (resp?.status === TokenStatus.waiting) {
      await sleep(interval);
      await checkIsVerified();
    }
  }

  async function resendVerificationMail() {
    const res = (await post(routes.resendSignupEmail(), {
      sessionId,
      type,
    })) as SignupResponse;
    if (res?.sessionId) {
      setEmailSent(true);
    }
  }

  useEffect(() => {
    if (sessionId) {
      (async () => {
        await checkIsVerified();
      })();
    }
  }, [sessionId]);

  return { resendVerificationMail, emailSent };
}
