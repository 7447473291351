import styled from 'styled-components';

export const SignupBoxForm = styled.form`
  display: grid;
  justify-content: center;
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: ${(p: any) => p?.gap || `1rem`};

  min-width: 55rem;
  height: auto;
  min-height: 10rem;

  padding: 3rem;

  background: white;

  box-shadow: 0 12px 19px 2px rgba(0, 0, 0, 0.04),
    0 9px 23px 4px rgba(0, 0, 0, 0.12), 0 6px 7px -3px rgba(0, 0, 0, 0.08);
  border-radius: 1.8rem;
`;
