import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

export interface SignupLayoutProps {
  children?: any;
}

export function SignupLayout({ children }: SignupLayoutProps) {
  return (
    <Wrapper>
      <CenteredContent>{children}</CenteredContent>
      <BlurredBackground />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;

  background: gray;
`;

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 10;

  && > * {
    z-index: 10;
  }
`;

export const BlurredBackground = () => (
  <BackgroundWrapper>
    <StaticImage
      formats={[`png`, `webp`]}
      placeholder="none"
      loading="eager"
      width={500}
      src="../assets/signup_background.png"
      alt=""
    />
  </BackgroundWrapper>
);

const BackgroundWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  filter: blur(10px);

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    opacity: 0.9;
  }
`;
