import React, { FC } from 'react';
import styled from 'styled-components';
import { useAwaitLoginVerify } from '@/features/signup/await_email/useAwaitLoginVerify';
import { SignupLayout } from '@/features/signup/shared/layout';
import { SignupBoxForm } from '@/features/signup/shared/box';
import {
  SignupLogo,
  SignupSubtitle,
  SignupTitle,
} from '@/features/signup/shared/components';
import { VSpace } from '@/components/spacing';

export const AwaitEmail: FC<{
  location: {
    state: { email: string; sessionId: string; type: 'signup' | 'login' };
  };
}> = ({ location }) => {
  const { resendVerificationMail, emailSent } = useAwaitLoginVerify(
    location?.state?.email,
    location?.state?.sessionId,
    location?.state?.type,
  );
  return (
    <SignupLayout>
      <SignupBoxForm>
        <SignupLogo />

        <VSpace value={2} />

        <SignupTitle>Check your inbox</SignupTitle>
        <SignupSubtitle>
          Click the link we sent to
          <br />
          {location?.state?.email} to complete your account set-up.
        </SignupSubtitle>

        <SendItAgainButton
          type="button"
          disabled={emailSent}
          onClick={async () => {
            await resendVerificationMail();
          }}
        >
          Send it again
        </SendItAgainButton>
        {emailSent && <SignupSubtitle>We just sent a new link.</SignupSubtitle>}

        <VSpace value={1} />

        <WaitingText>Waiting For Approval</WaitingText>
      </SignupBoxForm>
    </SignupLayout>
  );
};

const WaitingText = styled.p`
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  color: #95a0a6;
  padding: 2rem;
  border-radius: 1.2rem;
  text-align: center;
  font-family: Inter, serif;
  margin: 0;
  background: #f7f8f9
    linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 60%
    );
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 3s shine ease-in-out infinite;

  @keyframes shine {
    to {
      background-position-x: -20%;
    }
  }
`;

const SendItAgainButton = styled.button`
  background-color: transparent;
  border: none;
  appearance: none;
  outline: none;
  text-decoration: underline;
  color: #5b6971;
  font-size: 1.6rem;
  text-align: center;
  letter-spacing: -0.08px;
  margin: 0;
  padding: 0;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.6;
  }
`;
